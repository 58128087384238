<template>
  <div>
    <div class="replaceCon" v-loading="loading">
      <div class="detailsCon" v-if="detailInfo">
        <div class="dTitle">{{ detailInfo.title }} </div>
        <div class="dInfo">
          <span class="dTime">{{ parseTime(detailInfo.createdAt) }}</span>
          <span class="dEditor">编辑：<span>{{ detailInfo.authorName }}</span></span>
          <span style="color:#666666;margin-left:15px">点击量：<span>{{
                        detailInfo.viewBaseNum }}</span></span>
        </div>
        <div class="detailsText" v-html="detailInfo.content">

        </div>
      </div>
      <share-con></share-con>
    </div>
  </div>
</template>

<script>
  import shareCon from '@/components/shareCon'
  import {
    APPID,
    CHANNEL
  } from '@/utils/api/config'
  import {
    parseTime
  } from "@/utils/tools"
  import fenxiang from '../../../public/shareConfig.js';
  export default {
    components: {
      shareCon
    },
    data() {
      return {
        detailInfo: null,
        loading: true
      }
    },
    created() {
      //由于不知名原因导致无法分享 刷新后可以 所以这里刷新一下页面
      if (this.$route.query.reload) {
        this.$router.replace({
          name: this.$route.name
        })
        window.location.reload()
      } else {
        this.getData()
      }
    },
    methods: {
      //获取频道第一条数据
      getData() {
        this.axios.get(`api/fusion/pc/news/list?appId=${APPID}&channelId=${CHANNEL}&page=1&limit=1000`).then(res => {
          if (res.code !== '200') {
            this.$message.error(res.msg)
            return
          }
          let id = res.data.list[0].id
          this.axios.get(`api/fusion/pc/getNewsInfo?appId=${APPID}&newsId=${id}`).then(result => {
            this.detailInfo = result.data
            fenxiang(this.detailInfo.title, '',
              'http://o.cztvcloud.com/195/4727937/images/webologo.jpg', window.location.href);
            this.loading = false
          })
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .detailsBox {
    width: 1200px;
    margin: 0 auto;
  }

  .detailsCon {
    margin-top: 80px;
  }

  .dTitle {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    font-size: 36px;
    color: black;
    font-weight: bold;
  }

  .mainPageHead {
    height: 60px;
  }

  .dInfo {
    width: 50%;
    text-align: center;
    margin: 0 auto;
    font-size: 14px;
    color: #666;
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .dTime {
    margin-right: 15px;
  }

  .detailsText {
    width: 100%;
    min-height: 600px;
    padding: 0 150px;
    box-sizing: border-box;
  }

  .detailsTextImg {
    text-align: center;
  }

  .textImg {
    width: 647px;
    margin-bottom: 70px;
  }

  .detailsText p {
    line-height: 2;

    text-align: left;
  }

  .detailsText p span {
    font-size: 16px !important;
  }

  .detailsText p img {
    width: 650px;
    margin-bottom: 60px;
  }
</style>
